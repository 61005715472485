/* Table of contents
––––––––––––––––––––––––––––––––––––––––––––––––––
- Font Faces
- Base Styles
- Typography
- Links
- Alerts
- Modal
- Jumbo Image
- Panel
- Popover
- Utilities
- Forms
- Lists
- Tables
- Misc
- Clearing
*/



/* Font Faces
–––––––––––––––––––––––––––––––––––––––––––––––––– */

// Work Sans Light
@font-face {
    font-family: 'Work Sans Light';
    src: url('../fonts/WorkSans-Light.otf');
    font-weight: 300;
    font-style: normal;
}

// Work Sans Regular
@font-face {
    font-family: 'Work Sans Regular';
    src: url('../fonts/WorkSans-Regular.otf');
    font-weight: normal;
    font-style: normal;
}

// Work Sans Medium
@font-face {
    font-family: 'Work Sans Medium';
    src: url('../fonts/WorkSans-Medium.otf');
    font-weight: normal;
    font-style: normal;
}

// Work Sans Semibold
@font-face {
    font-family: 'Work Sans Semibold';
    src: url('../fonts/WorkSans-SemiBold.otf');
    font-weight: 600;
    font-style: normal;
}

// Work Sans Black
@font-face {
    font-family: 'Work Sans Black';
    src: url('../fonts/WorkSans-Black.otf');
    font-weight: 900;
    font-style: normal;
}

.fab {
    font-family: Font Awesome\ 5 Brands;
}

/* Base Styles
–––––––––––––––––––––––––––––––––––––––––––––––––– */
body, html {
    letter-spacing: 0.48px;
}


/* Typography
–––––––––––––––––––––––––––––––––––––––––––––––––– */
b, strong {
    font-family: 'Work Sans Medium';
}

p {
    margin-bottom: 1.2rem;

    @include media-breakpoint-up(sm) {
        margin-bottom: 1.2rem;
    }

    @include media-breakpoint-up(lg) {
        line-height: 1.56rem;
    }

    @include media-breakpoint-up(xl) {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }

}

.h1, h1 {
    hyphens: auto;
    font-size: 3rem;
    text-transform: uppercase;
    hyphens: none;
    font-size: 1.6rem;

    @include media-breakpoint-up(md) {
        font-size: 3.13rem;
    }

}

h2 {
    position: relative;
    font-size: 1.6rem;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
    }

    @include media-breakpoint-up(xl) {
        font-size: 3.13rem;
        margin-bottom: 1.5rem;
    }

}

h3 {
    font-size: 1.75rem;
    font-family: 'Work Sans Semibold';
}

ul {
    padding-left: 25px;
}


/* Links
–––––––––––––––––––––––––––––––––––––––––––––––––– */
a {
    outline: none !important;
}


/* Alerts
–––––––––––––––––––––––––––––––––––––––––––––––––– */


/* Modal
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.modal {
    -webkit-overflow-scrolling: touch;
}

.modal-content {
    border-radius: 0;
    border: none;
}

.modal-body {
    padding-top: 100px;

    @include media-breakpoint-up(lg) {
        padding-top: 140px;
    }

    ul {
        li {
            margin-bottom: 0.5rem;
        }
    }

}

.modal.fade .modal-dialog {
    max-width: none;
    transition: none;
    transform: none;
}

.modal.show .modal-dialog {
    transform: none;
}

.modal.fade .modal-content {
    opacity: 0;
    transform: translate3d(0,50px,0);
    transition: transform 0.6s, opacity 0.3s;
    transition-duration: 0.6s, 0.3s;
    transition-timing-function: ease, ease;
    transition-timing-function: cubic-bezier(0.7,1,0.8,1), ease;
}

.modal.show .modal-content {
    opacity: 1;
    transform: translate3d(0,0,0);
    transition-timing-function: cubic-bezier(0.2,1,0.3,1), ease;
    transition-duration: 1s;
}


/* Jumbo Image
–––––––––––––––––––––––––––––––––––––––––––––––––– */


/* Panel
–––––––––––––––––––––––––––––––––––––––––––––––––– */


/* Popover
–––––––––––––––––––––––––––––––––––––––––––––––––– */


/* Utilities
–––––––––––––––––––––––––––––––––––––––––––––––––– */

/* Forms
–––––––––––––––––––––––––––––––––––––––––––––––––– */


/* Lists
–––––––––––––––––––––––––––––––––––––––––––––––––– */


/* Tables
–––––––––––––––––––––––––––––––––––––––––––––––––– */


/* Misc
–––––––––––––––––––––––––––––––––––––––––––––––––– */


/* Clearing
–––––––––––––––––––––––––––––––––––––––––––––––––– */
