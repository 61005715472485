// ani-mouse
@keyframes ani-mouse {
    0% {
    opacity: 1;
    top: 29%;
    }
    15% {
    opacity: 1;
    top: 40%;
    }
    80% {
    opacity: 0;
    top: 40%;
    }
    100% {
    opacity: 0;
    top: 29%;
    }
}