// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    6;

// Fonts
//
$font-size-base: 1rem;
$font-family-base: "Work Sans Light";
$font-weight-base: 300;

$headings-font-family: "Work Sans Black";
$headings-font-weight: 700;

$line-height-lg: 1.6;
$body-color: #000000;

$h1-font-size: 8.75rem;
$h2-font-size: 2.38rem;
$h3-font-size: 1.69rem;

$headings-font-weight: 800;
$headings-margin-bottom: 0.8rem;

$fa-font-path:   "../fonts";

// Links
//
$link-color: black;
$link-hover-decoration: none;

// Grid breakpoints
//
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px
);

// Container
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1420px
);

// Modals
//
$modal-backdrop-bg: white;
$modal-backdrop-opacity: 1;

$modal-lg: 940px;

//Hamburger
//
$hamburger-padding-x: 0px;
$hamburger-padding-y: 0px;
$hamburger-layer-border-radius: 0px;
$hamburger-layer-height: 3px;
$hamburger-layer-color: #000000;
$hamburger-hover-opacity: 1;

// Sprite
@mixin arrow($arrow) {
  background-image: url( " #{ nth( $arrow, 3 ) } " );
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: 100%;
  background-position: center;
  width: nth( $arrow, 1 );
  height: nth( $arrow, 2 );
}

$arrow-right: 68px 17px "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='73px' height='20px' viewBox='0 0 73 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eicon_arrow_right_long%3C/title%3E%3Cg id='GR9086---Desktop-1680' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='square'%3E%3Cg id='GR9086---Desktop-1680---Burger' transform='translate(-1416.000000, -461.000000)' stroke='%23000000' stroke-width='3'%3E%3Cg id='Group' transform='translate(969.000000, 142.000000)'%3E%3Cg id='icon_arrow_right_long' transform='translate(449.000000, 322.000000)'%3E%3Cpath d='M61,0 L68,7 L61,14 M0,6.75 L66.25,6.75' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E";
$arrow-right-white: 68px 17px "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='73px' height='20px' viewBox='0 0 73 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eicon_arrow_right_long%3C/title%3E%3Cg id='GR9086---Desktop-1680' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='square'%3E%3Cg transform='translate(-1480.000000, -8971.000000)' id='06---Partner' stroke='%23FFFFFF' stroke-width='3'%3E%3Cg transform='translate(129.000000, 8786.000000)'%3E%3Cg id='icon_arrow_right_long' transform='translate(1353.000000, 188.000000)'%3E%3Cpath d='M61,0 L68,7 L61,14 M0,6.75 L66.25,6.75' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E";
$arrow-left: 68px 17px "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='73px' height='20px' viewBox='0 0 73 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eicon_arrow_right_long%3C/title%3E%3Cg id='GR9086---Desktop-1680' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='square'%3E%3Cg transform='translate(-786.000000, -8002.000000)' id='05---Verkaufsobjekte' stroke='%23000000' stroke-width='3'%3E%3Cg transform='translate(250.000000, 7069.000000)'%3E%3Cg id='Group-2' transform='translate(539.000000, 928.000000)'%3E%3Cg id='icon_arrow_right_long' transform='translate(34.000000, 15.000000) scale(-1, 1) translate(-34.000000, -15.000000) translate(0.000000, 8.000000)'%3E%3Cpath d='M61,0 L68,7 L61,14 M0,6.75 L66.25,6.75' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E";
$arrow-left-white: 68px 17px "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='73px' height='20px' viewBox='0 0 73 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eicon_arrow_right_long%3C/title%3E%3Cg id='GR9086---Desktop-1680' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='square'%3E%3Cg transform='translate(-126.000000, -8971.000000)' id='06---Partner' stroke='%23FFFFFF' stroke-width='3'%3E%3Cg transform='translate(129.000000, 8786.000000)'%3E%3Cg id='icon_arrow_right_long' transform='translate(34.000000, 195.000000) scale(-1, 1) translate(-34.000000, -195.000000) translate(0.000000, 188.000000)'%3E%3Cpath d='M61,0 L68,7 L61,14 M0,6.75 L66.25,6.75' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E";
