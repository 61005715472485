// Sidebar
//
aside.sidebar {
	position: fixed;
	top: 0;
	z-index: 1090;
	height: 100%;
	flex: 0 0 100%;
	max-width: 100%;
	background: #ffffff;
	transition: all 0.4s;
	transition-timing-function: cubic-bezier(0.7,0,0.3,1);
	transform: translate3d( calc(100vw + 100%), 0, 0);
	padding-top: 76px;

	@include media-breakpoint-up(lg) {
		flex: 0 0 50%;
		max-width: 50%;
	}

	body.open-menu & {
		transition: all 0.8s;
		transform: translate3d( calc(100vw - 100%), 0, 0);
	}

	a {
		/* pointer-events: none;
		backface-visibility: hidden;
		font-smoothing: antialiased; */
	}

	.sidebar-content {

		@include media-breakpoint-up(sm) {
			margin-left: 25px;
		}

		@include media-breakpoint-up(md) {
			margin-left: 70px;
		}

		@include media-breakpoint-up(lg) {
			margin-left: 50px;
		}

		@include media-breakpoint-up(xl) {
			margin-left: 70px;
		}

		section:not(:last-of-type) {
			margin-bottom: 1.2rem;

			@media (min-height: 600px) {
				margin-bottom: 1.5rem;
			}

			@include media-breakpoint-up(sm) {
				margin-bottom: 2.2rem;
			}
		}

		.social-links {
			font-size: 1.5rem;
			margin-left: -5px;

			li {
				margin-right: .8rem;

				a {
					padding: 5px;
				}

			}

		}

		// Animate from bottom
		.menu::after, .menu li, .textwidget {

			@include media-breakpoint-up(xl) {
				opacity: 0;
				transition-timing-function: cubic-bezier(0.7,0,0.3,1);
				transition: all 0s 0.4s;
				transform: translate3d(0,500px,0);
			}

			body.open-menu & {
				opacity: 1;
				transform: translate3d(0,0,0);
				transition: all 0.8s;
				transition-duration: 0.8s;
				transition-delay: 0.1s;
				transition-timing-function: ease;
				transition-timing-function: cubic-bezier(0.7,0,0.3,1);
			}

		}

		.menu {

			&:after {
				@include media-breakpoint-up(xl) {
					transform: translate3d(0,2500px,0);
				}
			}

			li {

				&:nth-child(1) {
					@include media-breakpoint-up(xl) {
						transform: translate3d(0,1500px,0);
					}
				}

				&:nth-child(2) {
					@include media-breakpoint-up(xl) {
						transform: translate3d(0,2000px,0);
					}
				}

				&:nth-child(3) {
					@include media-breakpoint-up(xl) {
						transform: translate3d(0,2500px,0);
					}
				}

				&:nth-child(4) {
					@include media-breakpoint-up(xl) {
						transform: translate3d(0,3000px,0);
					}
				}

				&:nth-child(5) {
					@include media-breakpoint-up(xl) {
						transform: translate3d(0,3500px,0);
					}
				}

				&:nth-child(6) {
					@include media-breakpoint-up(xl) {
						transform: translate3d(0,4000px,0);
					}
				}

				&:nth-child(7) {
					@include media-breakpoint-up(xl) {
						transform: translate3d(0,4500px,0);
					}
				}

			}

		}

		.textwidget {

			&:nth-of-type(1) {
				@include media-breakpoint-up(xl) {
					transform: translate3d(0,3000px,0);
				}
			}

			&:nth-of-type(2) {
				@include media-breakpoint-up(xl) {
					transform: translate3d(0,3500px,0);
				}
			}

		}

	}

	#menu-primary-navigation {
		list-style: none;
		padding-left: 0;
		position: relative;
		margin-bottom: 1.2rem;

		@include media-breakpoint-up(xl) {
		    width: 450px;
		    margin-bottom: 3.5rem;
		}

		li {

			a {
				font-family: 'Work Sans Black';
				font-size: 1.3rem;
				text-transform: uppercase;

				@media (min-height: 600px) {
					font-size: 1.6rem;
				}

				@include media-breakpoint-up(sm) {
					font-size: 2.75rem;
				}

				@include media-breakpoint-up(lg) {
					font-size: 2rem;
				}

				@include media-breakpoint-up(xl) {
					position: relative;
					overflow: hidden;
					display: block;
					outline: none;
					vertical-align: bottom;
					text-decoration: none;
					white-space: nowrap;
					margin-top: 1.2rem;
					margin-bottom: 1.2rem;
				}

				&:after {
					@include media-breakpoint-up(xl) {
						@include arrow($arrow-right);
						display: none;
						content: "";
						right: 0;
						top: 17px;
					    position: absolute;
					    z-index: 2;
					}
				}

				&:hover {
					&:after {
						@include media-breakpoint-up(xl) {
							display: block;
						}
					}
				}

			}

		}

	}

}
