// Header
//
header.banner {
	position: fixed;
    right: 0;
    left: 0;
    z-index: 1070;
    background: #fff;
    padding: 0.5rem 0;

    @include media-breakpoint-down(lg) {
        border-bottom: 1px solid #E7E7E7;
    }

    @include media-breakpoint-up(xl) {
    	padding: 2.38rem 1rem;
        border-bottom: none;
		transition: all 0.3s;
	}

	body.modal-open & {
		@include media-breakpoint-up(xl) {
			box-shadow: none;
		}
	}

    body.shrink & {
        @include media-breakpoint-up(xl) {
            border-bottom: 1px solid #E7E7E7;
            box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.2);
            padding-top: 0.9rem;
            padding-bottom: 0.9rem;
        }
    }

}

.brand {
	display: block;

	img {
		height: 60px;

		@include media-breakpoint-up(xl) {
			height: 90px;
		}

	}

}

.hamburger {
	padding-top: 5px;
	z-index: 1100;
    position: fixed;
    top: 20px;

    @include media-breakpoint-up(xl) {
    	top: 46px;
    }

	&:not(.is-active) {

		.hamburger-inner {
			transform-origin: 45% 50%;
			width: 32px;
			right: 0;

			&:before {
				width: 32px;
				top: -9px;
				right: 0;
			}

			&:after {
				width: 23.5px;
				bottom: -9px;
				right: 0;
			}

		}

	}

	.hamburger-box {
		height: 25px;
	}

}
