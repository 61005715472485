footer.content-info {
	padding-bottom: 2rem;
	text-align: center;

	@include media-breakpoint-up(xl) {
		text-align: left;
	    padding-bottom: 58px;
	}

	section {
		@include make-col-ready();

		&:first-of-type {
			@include media-breakpoint-up(lg) {
		        @include make-col(4);
		    }
		}

		&:nth-of-type(2) {
			@include media-breakpoint-up(lg) {
		        @include make-col(8);
		        text-align: right;
		    }
		}

	}

	ul {
		margin-bottom: 0.5rem;
	}

	.social-links {
		@include media-breakpoint-down(lg) {
		    margin-bottom: 1rem;
		}

		.list-inline-item:not(:last-child) {
			margin-right: 1rem;

			@include media-breakpoint-up(lg) {
				margin-right: 1.5rem;
			}
		}
	}

	p {
		font-size: 1rem;
	}

}
